import nationalities from './data/nationalities.json'
import politicals from './data/politicals.json'

type Option = { value: string | number; label: string; name?: string }

export const options = (options: Option[]) => ({
  withAll: () => [{ value: '', name: 'all', label: '全部' }, ...options],

  find: (value: string | number) => options.find(option => option.value === value)
})

export const equipment = {
  statuses: [
    { value: 0, name: 'online', label: '在线' },
    { value: 1, name: 'offline', label: '离线' },
    { value: 3, name: 'disabled', label: '未启用' },
    { value: 9, name: 'unknown', label: '未知' }
  ]
}

export const house = {
  statuses: [
    { value: 'derelict', label: '无主', name: 'derelict' },
    { value: 'empty', label: '空置', name: 'empty' },
    { value: 'occupied', label: '已入住', name: 'occupied' },
    { value: 'overmanned', label: '超员', name: 'overmanned' }
  ],

  types: [
    { value: 'leased', label: '出租' },
    { value: 'housing', label: '自住' },
    { value: 'empty', label: '未住' }
  ],

  searchTypes: [
    { label: '门牌号', value: 'name' },
    { label: '户主姓名', value: 'landlordName' }
  ]
}

export const resident = {
  types: [
    { value: 'landlord', label: '户主' },
    { value: 'relative', label: '亲属' },
    { value: 'renter', label: '租客' }
  ],

  identityTypes: [
    { value: 'identityNumber', label: '身份证' },
    { value: 'passportNumber', label: '护照' },
    { value: 'hkmoPermitNumber', label: '港澳居民来往内地通行证' },
    { value: 'twnPermitNumber', label: '台湾居民来往大陆通行证' }
  ],

  searchTypes: [
    { label: '住户姓名', value: 'name' },
    { label: '联系电话', value: 'tel' }
  ]
}

export const application = {
  statuses: [
    { label: '待审核', value: 0 },
    { label: '已通过', value: 1 },
    { label: '未通过', value: 2 },
    { label: '申请已过期', value: 3 }
  ],

  searchTypes: [
    { label: '访客姓名', value: 'name' },
    { label: '证件号', value: 'identityNumber' },
    { label: '联系电话', value: 'tel' }
  ]
}

export const visitor = {
  statuses: [
    { label: '未过期', value: 0 },
    { label: '已过期', value: 3 }
  ],

  types: [
    { label: '代注册', value: 'manager' },
    { label: '自注册', value: 'self' },
    { label: '邀请注册', value: 'invitation' }
  ],

  searchTypes: [
    { label: '访客姓名', value: 'name' },
    { label: '证件号', value: 'identityNumber' }
  ]
}

export const information = {
  nationalities: nationalities.map(value => ({ label: value, value })),

  politicals: politicals.map(({ id, political }) => ({ label: political, value: id }))
}

export const area = {
  level: [
    { value: 0, label: '普通区域' },
    { value: 1, label: '社区(村)级区域' },
    { value: 2, label: '区、街道(乡)级区域' }
  ],

  addressTypes: [
    { value: 1, label: '楼房小区' },
    { value: 2, label: '村庄和平房院落' }
  ]
}

export const verification = {
  processStatuses: [
    { label: '未匹配报备信息', value: 'unmatchedReporting' },
    { label: '未判定', value: 'undecided' },
    { label: '已判定为误报', value: 'misstatement' },
    { label: '已判定为飞单', value: 'flyingOrder' },
    { label: '已判定为其它', value: 'others' }
  ]
}

export const tag = {
  types: [
    { value: 'vip', label: 'VIP' },
    { value: 'white', label: '白名单' },
    { value: 'black', label: '黑名单' }
  ]
}
