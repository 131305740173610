

































































































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import SecurityText from '@/components/common/SecurityText/index.vue'
import { information, options, resident } from '@/utils/options'
import { is } from '@/utils/helpers'

@Component({ components: { SecurityText } })
export default class ResidentDetail extends Vue {
  @Ref() track: HTMLDivElement

  person: AnyObj = {}
  houseList: AnyObj[] = []
  trackList: AnyObj[] = []
  citizens: { label: string; value: string }[] = []
  loading = false
  securityTextVisible = { name: false, identity: false, landlordName: false, landlordTel: false }

  get user() {
    return this.$store.state.user || {}
  }

  get personId() {
    return this.$route.params.id
  }
  get isLandlord() {
    return this.person.type === 'landlord'
  }
  get formatCardNumber() {
    return this.person.cardNumber?.split(';')
  }
  get toolTipCards() {
    // 如果有多张门禁卡，显示tool-tip，并且内容为xxx / xxx，一张卡或无卡不显示
    if (this.formatCardNumber?.length > 1) {
      this.toolTipCardsShow = true
      return this.person.cardNumber?.split(';').join(' / ')
    } else {
      this.toolTipCardsShow = false
      return null
    }
  }
  get cards() {
    // 如果有多张门禁卡就显示第一张门禁卡，并且后面有...，否则显示一张卡就行
    if (this.person.cardNumber) {
      return this.formatCardNumber.length > 1
        ? this.formatCardNumber[0] + '...'
        : this.formatCardNumber[0]
    }
    return '无'
  }
  get type() {
    return options(resident.types).find(this.person.type) ?? {}
  }

  get identity() {
    for (const { value, label } of resident.identityTypes) {
      if (this.person[value]) {
        return { type: value, label, value: this.person[value] }
      }
    }

    return { type: '', label: '-', value: '-' }
  }

  get gender() {
    const map: Obj<string, string> = {
      male: '男',
      female: '女'
    }

    return map[this.person.gender] ?? '未知'
  }

  get political() {
    return this.text(options(information.politicals).find(this.person.political)?.label)
  }

  get nationality() {
    return this.text(options(information.nationalities).find(this.person.nationality)?.label)
  }

  get statistic() {
    return this.person.statistic ?? {}
  }

  get permanent() {
    return this.person.permanent ? '常住' : '流动'
  }

  get citizenship() {
    return this.citizens.find(({ value }) => value === this.person.citizenship)?.label ?? '-'
  }

  activated() {
    this.loading = true
    Promise.all([
      this.fetchPerson(),
      this.fetchCitizens(),
      this.fetchHouses(),
      this.fetchTracks()
    ]).then(() => (this.loading = false))
  }

  deactivated() {
    this.person = {}
    this.houseList = []
    this.trackList = []
    this.securityTextVisible = {
      name: false,
      identity: false,
      landlordName: false,
      landlordTel: false
    }
  }

  text(content?: string) {
    return content ?? '无'
  }

  async fetchPerson() {
    const { data } = await this.$api.europa.getPerson(this.personId)

    if (data.code === 0) {
      this.person = data.data
    }
  }

  async fetchHouses() {
    const { data } = await this.$api.europa.getHousesByPerson(this.personId)

    if (data.code === 0) {
      this.houseList = data.data
    }
  }

  async fetchCitizens() {
    const { data } = await this.$api.static.getCitizenship()

    this.citizens = data
  }

  async fetchTracks() {
    const { data } = await this.$api.v2.getPersonTraceApi(this.personId, {
      scopeId: this.user.root
    })

    if (data.code === 0) {
      this.trackList = data.data
    }
  }

  getAddress(paths: AnyObj[]) {
    return paths.map(i => i.name).join('/')
  }

  getTraces(trips: AnyObj[]) {
    return trips.reduce<AnyObj[]>((prev, curr) => [...prev, ...curr.trace], [])
  }

  backToResidents() {
    if (history.length > 1) {
      this.$router.back()
    } else {
      this.$router.push({ name: '房屋管理' })
    }
  }

  viewImage() {
    if (this.person.imageUrl) {
      this.$msgbox({
        customClass: 'resident-image-message-box',
        message: <img src={this.person.imageUrl} />,
        showConfirmButton: false,
        showClose: true
      })
    }
  }

  scrollTrack(index: number, offset: number) {
    if (is.arr(this.track)) {
      const el = this.track[index] as HTMLDivElement

      if (el) {
        el.scrollLeft += offset
      }
    }
  }
}
